.appLogo {
  width: 115px;
  height: 115px;
  border-radius: 20%;
}

.appItem {
  padding: 20px;
  text-align: center;
  min-width:160px;
}

.appName {
  text-align: center;
}


@media screen and (min-width: 3000px) {
  .appLogo {
    width: 130px;
    height: 130px;
  }

  .appName {
    font-size: 16px;
  }
}
