.issuesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.issue {
  display: flex;
  flex-direction: row;
  justify-content: center;
}