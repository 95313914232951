.darkCustomRow:hover td {
   background: rgba(255, 255, 255, 0.108) !important;
}

.lightCustomRow:hover td {
   background: rgba(71, 71, 71, 0.098) !important;
}

.lightfail {
    background-color: #f71d0514
 }

 .darkfail {
    background-color: #f9716528
 }

 .lightpass {
    background-color: #6df10819
 }

 .darkpass {
    background-color: #6fff0024
 }

 .lightcancel {
    background-color: #fce69c30;
 }

 .darkcancel {
    background-color: #f6be052c;
 }

 .description {
    padding: 20px;
 }

 .sexTags {
    margin-left: 30px;
 }

 .statusDetail{
    display: flex;
    justify-content: space-between;
    align-items: center;
 }

 .detailTag {
    min-width: 32px;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 5px;
 }

 .failedSnps{
    margin: 5px auto;
    display: inline-block;
    text-align: center;
 }

 .signed {
   background-color: #ebf3ff;
   border: 1px dotted #dbd9d9;
 }

