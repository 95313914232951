.appHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  align-content: flex-start;
  min-height: calc(100vh - 220px);
}

.linksContainer {
  display: flex;
  justify-content: center;
}