.githubIcon {
  width: 27px;
  margin-right: 9px;
}

.gitHubLogin {
    padding: '0';
    display: 'flex';
    border-radius: 0;
    border-bottom: 0;
}

.userCard {
  padding: '0';
  display: 'flex';
  align-items: 'center';
}

.userAvatar {
  margin-right: 9px;
}

.sideMenu{
  height: calc(100% - 80px);
}