.loginButton {
  position: absolute;
  top: 0px;
  right: 75px;
}

.userDropdown {
  position: absolute;
  top: 0;
  right: 50px;
}

.logo {
  width: 50px;
  float: left;
  margin: 7px 30px 0 -15px;
}

.googleAvatar {
  max-width: 45px;
  padding: 0 15px 2px 0;
}

.content {
  padding: 24px;
  min-height:calc(100vh - 108px);
  padding-bottom: 0px;
}
