.actionBox {
  display: flex;
  flex-direction: row;
  font-size: 1.7em;
}

.actionIcons {
  margin-right: 20px;
  cursor: pointer;
  font-size: 18px;
}

.signed {
  background-color: #86fe6e2d
}